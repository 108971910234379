import React from "react";
import { useTranslation } from "react-i18next";

const Slider = () => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div className="theme_slider_1">
      <div className="slider">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="slide_content">
                <h5 className="sub_heading">
                  {t("slider.callUs")}
                  <span>{t("slider.phoneNumber")}</span>
                </h5>
                <h2 className="heading">{t("slider.welcome")}</h2>
                <p className="desc">{t("slider.description")}</p>
                <div className="slider_button">
                  <a href="/about" className="button">
                    {t("slider.learnMore")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center">
              <div className="layer_object">
                <img src="images/slider/ecology-earth.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
