import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Controller, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next'; // Import useTranslation

import TestimonialCard01 from '../../Components/TestimonialCards/TestimonialCard01';
import useAxiosFetch from '../../hooks/useAxiosFetch';
import "./Testimonial.css"

const Testimonial01 = (props) => {
  const { t } = useTranslation(); // Initialize the t function

  const { data: testimonialData, loading, error } = useAxiosFetch('testimonials/');

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <div className={`testimonial ${props.pb} `}>
      <div className="container">
        <div className="section_header text-center">
          <div className="shadow_icon">
            <img src="images/shadow_icon3.png" alt="" />
          </div>
          <h6 className="section_sub_title">{t('clientTestimonial')}</h6>
          <h1 className="section_title">{t('whatClientsSay')}</h1>
          <p className="section_desc">{t('bestConstructionCompany')}</p>
        </div>
        <div className="row">
          <div className="col-12">
            <Swiper
              className="owl_testimonial1 owl-carousel owl-theme"
              modules={[Pagination, Controller]}
              effect={"slide"}
              breakpoints={{
                640: {
                  width: 640,
                  slidesPerView: 1,
                },
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
              }}
              loop={true}
              controller={{ inverse: true }}
              spaceBetween={50}
              pagination={{
                el: '.testimonial-pagination',
                clickable: true,
              }}
            >
              {testimonialData.map((data) => (
                <SwiperSlide key={data.id}>
                  <TestimonialCard01 data={data} />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <!-- Add Pagination --> */}
            <div className="testimonial-pagination"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial01;
