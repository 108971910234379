import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../axios-config";

function ContactPageForm1() {
  const { t } = useTranslation(); // Initialize the t function for translations

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State variable for loading

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Clear any previous error messages
    setErrorMessage(null);

    // Validate non-alphanumeric characters in the name field
    if (name === "name" && !validateName(value)) {
      setErrorMessage("Name should contain only alphabets and spaces");
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Clear any previous error or success messages
    setErrorMessage(null);
    setSuccessMessage(null);

    const fieldsToValidate = ["name", "email", "phone", "subject", "message"];
    const requiredFields = {
      name: "Name",
      email: "Email",
      phone: "Phone",
      subject: "Subject",
      message: "Message",
    };

    // Check if any of the fields is empty
    for (const field of fieldsToValidate) {
      if (isFieldEmpty(formData[field])) {
        setErrorMessage(`${requiredFields[field]} is required`);
        return;
      }
    }
    // Check phone number format
    if (!validatePhoneNumber(formData.phone)) {
      setErrorMessage("Please enter a valid phone number");
      return;
    }

    // Check email format
    if (!validateEmail(formData.email)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    // Check character limits for email and subject
    if (
      !validateCharacterLimit(formData.email, 250) ||
      !validateCharacterLimit(formData.subject, 250)
    ) {
      setErrorMessage("Email and subject must be less than 250 characters");
      return;
    }

    // Set isLoading to true while submitting
    setIsLoading(true);

    // Send a POST request to your backend API
    instance
      .post("contacts/", JSON.stringify(formData))
      .then((response) => {
        // Handle success, e.g., show a success message
        setSuccessMessage(t("contactSuccess"));
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
        setErrorMessage(null);
        setIsLoading(false); // Set isLoading back to false after submission
        // Clear the success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000);

        // console.log("Form submitted successfully", response.data);
      })
      .catch((error) => {
        // Handle errors, e.g., display an error message
        setErrorMessage("Error submitting the form");
        setIsLoading(false); // Set isLoading back to false on error

        // Clear the error message after 5 seconds
        setTimeout(() => {
          setErrorMessage(null);
        }, 5000);

        // console.error("Error submitting the form", error);
      });
  };

  // Function to validate non-alphanumeric characters in the name field
  const validateName = (name) => {
    const regex = /^[A-Za-z\s]*$/; // Only allows alphabets and spaces
    return regex.test(name);
  };

  // Function to validate phone number format
  const validatePhoneNumber = (phone) => {
    // Implement phone number validation logic here
    // Example: Check if it contains only digits and is 10 characters long
    const phoneRegex = /^(\+[0-9]{1,4}-?)?[0-9]{6,12}$/;
    return phoneRegex.test(phone);
  };

  // Function to validate email format
  const validateEmail = (email) => {
    // Implement email validation logic using a regular expression
    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
    return emailRegex.test(email);
  };

  // Function to validate character limit
  const validateCharacterLimit = (value, limit) => {
    return value.length <= limit;
  };

  // Function to check if a field is empty
  const isFieldEmpty = (value) => {
    return value.trim() === "";
  };

  return (
    <div>
      {errorMessage && (
        <div
          className="alert alert-danger"
          role="alert"
          style={{ fontSize: 16, padding: 8 }}
        >
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div
          className="alert alert-success"
          role="alert"
          style={{ fontSize: 16, padding: 8 }}
        >
          {successMessage}
        </div>
      )}

      <form className="contact_form" onSubmit={handleSubmit}>
        <div className="form-container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder={t("Your Name")}
                  required=""
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder={t("Email Address")}
                  required=""
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder={t("Phone No")}
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  placeholder={t("Subject")}
                  value={formData.subject}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <textarea
                  name="message" // Change the name to "message"
                  className="form-control"
                  placeholder={t("Message Here")} // Set the placeholder to "Message Here"
                  required=""
                  value={formData.message} // Bind the value to the state
                  onChange={handleChange} // Add an onChange handler to update the state
                ></textarea>
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <button
                  className="button"
                  type="submit"
                  disabled={isLoading} // Disable the button while loading
                >
                  {isLoading ? "Loading..." : t("Send Mail")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactPageForm1;
