import React from "react";
import { useTranslation } from "react-i18next";

const ExperienceAbout = () => {
  const { t } = useTranslation();

  const translationKeys = {
    title: t("experienceAbout.title"),
    vision: t("experienceAbout.companyVision"),
    plan: t("experienceAbout.companyPlan"),
    commitment: t("experienceAbout.companyCommitment"),
    generalManager: t("generalManager.nameAndTitle"),
  };

  return (
    <div className="experience">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="experience_content about">
              <div className="section_header">
                <h6 className="section_sub_title">
                  {translationKeys.generalManager}
                </h6>
                <h1 className="section_title">{translationKeys.title}</h1>
                <p className="section_desc" style={{ marginBottom: "20px" }}>
                  {translationKeys.vision}
                </p>
                <p className="section_desc" style={{ marginBottom: "20px" }}>
                  {translationKeys.plan}
                </p>
                <p className="section_desc">{translationKeys.commitment}</p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="group_image_holder">
              <img src="images/about/about.jpg" alt="Bigeta Business" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceAbout;
