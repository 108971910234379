import React from "react";
import getDayMonthYear from "../../utills/getDayMonthyear";

const BlogCards01 = ({ data }) => {
  const {
    blogClass,
    id,
    title,
    image,
    content,
    author,
    created_at,
    updated_at,
    is_published,
  } = data;
  const { day, month, year } = getDayMonthYear(updated_at);
  return (
    <div className={blogClass ?? "col-lg-4 col-md-6"}>
      <article className="blog_post">
        <div className="post_img">
        <a href={`/blog-details/${id}`}>
            <img src={image} alt="img" />
          </a>
          <div className="calendar">
            <a href="#">
              <span className="date">{day}</span>
              <br />
              {month}
            </a>
          </div>
        </div>
        <div className="post_content">
          <div className="post_header">
            <h3 className="post_title" style={{ height: "2.5em" ,overflow:"hidden"}}>
              <a href={`/blog-details/${id}`}>{title}</a>
            </h3>
          </div>
          <div
            className="post_intro"
            style={{
              maxHeight: "5em",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <p>{content}</p>
          </div>
          <div className="post_footer">
            <div className="read_more">
              <a href={`/blog-details/${id}`}>
                <span>Read Article</span>
              </a>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default BlogCards01;
