import React from 'react';
import FunBox02 from '../../Components/FunFactElements/FunBox02';
import useAxiosFetch from '../../hooks/useAxiosFetch';

const FunFactAbout = () => {
    const { data: licenseData, loading, error } = useAxiosFetch("licenses/");

    // const licenses = [
    //     {
    //       name: 'WWC G-1',
    //       description: 'Our WWC G-1 License allows us to work on exciting water-related projects.',
    //       img:"/images/funfact/license7.png"
    //     },
    //     {
    //       name: 'Electromechanical Contractor G-2',
    //       description: 'We hold the Electromechanical Contractor G-2 license, which empowers us to create innovative electromechanical systems.',
    //       img:"/images/funfact/license7.png"
    //     },
    //     {
    //       name: 'Building & Road Contractor G-3',
    //       description: 'Our Building & Road Contractor G-3 License lets us construct impressive buildings and roads.',
    //       img:"/images/funfact/license7.png"
    //     },
    //     // Add more licenses as needed
    //   ];
      
  
    return (
        <div id="funfacts" className="funfacts2 section">
            <div className="funfacts3_bg_img">
                <div className="container">

                    <div className="row">
                        <div className="col">
                            <div className="funfacts3_bg">
                                {
                                    licenseData.map(data =>
                                        <FunBox02
                                            key={data.id}
                                            data={data}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunFactAbout;