import $ from "jquery";
import React, { useEffect } from "react";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import { useParams } from "react-router-dom";
import HTMLRenderer from "../../Components/HTMLRenderer";
import SocialShareButtons from "../../Components/SocialShareButtons";

const ProjectDetailsContainer = () => {
  const { projectId } = useParams();
  const { data, loading, error } = useAxiosFetch(`project/${projectId}/`);

  useEffect(() => {
    // accordion
    $(".accordion").on("click", ".accordion_tab", function () {
      $(this).next().slideDown();
      $(".accordion_info").not($(this).next()).slideUp();
    });

    $(".accordion").on("click", ".item", function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
  }, []);
  const {
    project_name,
    description,
    address,
    client,
    date_of_work,
    image,
    execution_amount,
    testimonial,
    category,
  } = data;
  const shareUrl = window.location.href;
  return (
    <div class="main_wrapper">
      <div class="container">
        <div class="project_details section">
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="project_details_inner">
                <div class="post_img">
                  <img src={image} alt="proj-detail" />
                </div>
                <div class="post_content">
                  <div class="post_header">
                    <h3 class="post_title">{project_name}</h3>
                  </div>
                  <div className="fulltext">{HTMLRenderer(description)}</div>
                </div>
              </div>
              {testimonial && (
                <div class="testibox1">
                  <div class="testibox_inner">
                    <div class="testi-content">
                      <ul>
                        <li class="text">Rating:</li>

                        {testimonial?.rating &&
                          [...Array(testimonial?.rating)].map((_, index) => (
                            <li key={index}>
                              <i className="fa fa-star"></i>
                            </li>
                          ))}
                        {testimonial?.rating &&
                          [...Array(5 - testimonial?.rating)].map(
                            (_, index) => (
                              <li key={index}>
                                <i className="fa fa-star-o"></i>
                              </li>
                            )
                          )}
                      </ul>
                      <p>{testimonial?.description}</p>
                    </div>
                    <div class="testi-top">
                      <div class="testi-img">
                        <img src={testimonial?.picture} alt="" />
                      </div>
                      <div class="testi-info">
                        <h4>{testimonial?.name}</h4>
                        <h6>{testimonial?.address}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div class="share_tag">
                <SocialShareButtons shareUrl={shareUrl} title={project_name} />
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <div class="sidebar">
                <div class="project_info">
                  <div class="project_info_bg">
                    <div class="project_info_header">
                      <h4>Project Information</h4>
                    </div>
                    <div class="project_info_details_bg">
                      <div class="project_info_details">
                        <h5>Client Name</h5>
                        <p>{client}</p>
                      </div>
                      {category?.name && (
                        <div class="project_info_details">
                          <h5>Category</h5>
                          <p>{category?.name}</p>
                        </div>
                      )}
                      <div class="project_info_details">
                        <h5>Date of Complishied</h5>
                        <p>{date_of_work}</p>
                      </div>
                      <div class="project_info_details">
                        <h5>Client Address</h5>
                        <p>{address}</p>
                      </div>
                      <div class="project_info_details">
                        <h5>Client Rating</h5>
                        <ul>
                          {testimonial?.rating &&
                            [...Array(testimonial?.rating)].map((_, index) => (
                              <li key={index}>
                                <i className="fa fa-star"></i>
                              </li>
                            ))}
                          {testimonial?.rating &&
                            [...Array(5 - testimonial?.rating)].map(
                              (_, index) => (
                                <li key={index}>
                                  <i className="fa fa-star-o"></i>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsContainer;
