import React from "react";
import PageHeader from "../Components/common/PageHeader";
import Partners02 from "../Components/Partners/Partners02";
import TopFeatures from "../Components/TopFeatures";
import Banner from "../Containers/Banner";
import ExperienceAbout from "../Containers/Experiences/ExperienceAbout";
import FunFactAbout from "../Containers/Funfacts/FunFactAbout";
import Team from "../Containers/Team";
import Testimonial01 from "../Containers/Testimonials/Testimonial01";
import { useTranslation } from "react-i18next";
import CompanyInfo from "../Components/CompanyInfo/CompanyInfo";

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader
        activeClass01="active"
        activeClass02="d-none"
        routeName01="About Us"
        heading="About Company"
      />

      <div className="main_wrapper">
        <TopFeatures />

        <ExperienceAbout />
        <CompanyInfo />
        <FunFactAbout />

        <Team pb="pd_btom_110" />

        <Banner
          title={t("bannerTitle")}
          heading={t("bannerHeading")}
          phones={{ phone1: "+251 116 479 506", phone2: "+251 116 479 504" }}
          bannerType="banner type_3"
        />

        <Testimonial01 pb="pd_btom_110" />
        <Partners02 pt="pd_tp_110" />
      </div>
    </>
  );
};

export default About;
