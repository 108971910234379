import React from 'react';
import { useTranslation } from 'react-i18next';

const PageHeader = (props) => {
    const { t } = useTranslation();

    return (
        <div className="page_header">
            <div className="page_header_content">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="/">{t('header.home')}</a></li>
                        <li className={props.activeClass01}><a href={props.route01}> {t(props.routeName01)} </a></li>
                        <li className={props.activeClass02}> {t(props.routeName02)} </li>
                    </ul>
                    <h2 className="heading">{t(props.heading)}</h2>
                </div>
            </div>
        </div>
    );
};

export default PageHeader;
