import React, { useState } from "react";
import usePaginatedData from "../../hooks/usePaginatedData";
import PaginationDiv from "../../Components/common/Pagination";
import ReplyForm from "./ReplyForm";
import getDayMonthYear from "../../utills/getDayMonthyear";

function CommentList({ blogId }) {
  // Use the usePaginatedData hook to fetch comments
  const {
    data: comments,
    currentPage,
    totalPages,
    handlePageChange,
  } = usePaginatedData(`blog/${blogId}/comments/`, 4);

  // State to track which comment is being replied to
  const [replyToComment, setReplyToComment] = useState(null);

  const handleReplyClick = (commentId) => {
    // Set the comment ID that you want to reply to
    setReplyToComment(commentId);
  };
  const getDate=(date)=>{
    const { day, month, year } = getDayMonthYear(date);
    return day +" "+month+" "+year
  }

  return (
    <div className="comment_sec">
      <h4 className="widget_title">
        Comments
        <span className="title_line"></span>
      </h4>
      <ul className="comment_area">
        {comments.map((comment, index) => (
          <li key={index} className="blog_comment_user">
            <div className="commenter_div">
              <div className="commenter">
                <img
                  alt="img"
                  src="/images/commentor2.png" // You may need to update the image source
                  className="avatar"
                  style={{ width: 50, height: 50 }}
                />
              </div>
              <div className="comment_block">
                <h4 className="commenter_name">
                  {comment.name}{" "}
                  <span className="reply">
                    <a
                      href="#"
                      className="reply"
                      onClick={() => handleReplyClick(comment.id)} // Set the comment ID to reply to
                    >
                      Reply
                    </a>
                  </span>
                </h4>
                <p className="commenter_review">{comment.text}</p>
                <h6 className="comment_date">{getDate(comment.created_at)}</h6>

                {/* Render reply form if this comment is being replied to */}
                {replyToComment === comment.id && (
                  <ReplyForm commentId={comment.id} />
                )}
              </div>
            </div>
            <ul className="children">
              {comment.replies.map((reply, index) => (
                <li key={index} className="blog_comment_user">
                  <div className="commenter_div">
                    <div className="commenter">
                      <img
                        alt="img"
                        src="/images/reply2.png" // You may need to update the image source
                        className="avatar"
                        style={{ width: 50, height: 50 }}
                      />
                    </div>
                    <div className="comment_block">
                      <h4 className="commenter_name">{reply.name} </h4>
                      <p className="commenter_review">{reply.text}</p>
                      <h6 className="comment_date">{getDate(reply.created_at)}</h6>

                      {/* Render reply form if this comment is being replied to */}
                      {/* {replyToComment === comment.id && (
              <ReplyForm commentId={comment.id} />
            )} */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <div className="comments-pagination">
        <PaginationDiv
          pageCount={totalPages} // Pass the total number of pages
          currentPage={currentPage} // Pass the current page
          onPageChange={handlePageChange} // Pass the page change handler
        />
      </div>
    </div>
  );
}

export default CommentList;
