import React, { useState } from "react";
import MobileNav from "./MobileNav/MobileNav";
import LanguageToggle from "../../LanguageToggle";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  window.onscroll = function () {
    myFunction();
  };
  function myFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.querySelector("header").classList.add("dark_mode");
      document.querySelector("header .top_bar").classList.add("top-up");
    } else {
      document.querySelector("header").classList.remove("dark_mode");
      document.querySelector("header .top_bar").classList.remove("top-up");
    }
  }

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <>
      <header className="header">
        <div className="top_bar" id="top-bar">
          <div className="container">
            <div className="top_bar_inner">
              <div className="header_social">
                <h6>{t("Follow Us")}</h6>
                <ul className="top_social">
                  <li className="facebook">
                    <a href="#">
                      <i className="ion-social-facebook"></i>
                    </a>
                  </li>
                  <li className="twitter">
                    <a href="#">
                      <i className="ion-social-twitter"></i>
                    </a>
                  </li>
                  <li className="dribbble">
                    <a href="#">
                      <i className="ion-social-dribbble-outline"></i>
                    </a>
                  </li>
                  <li className="instagram">
                    <a href="#">
                      <i className="ion-social-instagram-outline"></i>
                    </a>
                  </li>
                  <li className="linkedin">
                    <a href="#">
                      <i className="ion-social-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header_info">
                <div className="schedule">
                  <div
                    className="hide_icon d-md-none"
                    data-text={t("Monday - Friday (9.00am - 9.00pm)")}
                  >
                    {" "}
                    <span>
                      <i className="fa fa-clock-o"></i>
                    </span>
                  </div>
                  <div className="d-none d-md-block">
                    <span>
                      <i className="fa fa-clock-o"></i>
                    </span>{" "}
                    {t("Translate_Days_Here")}{" "}
                    <strong>({t("Translate_Hours_Here")})</strong>
                  </div>
                </div>
                <div className="schedule">
                  <div
                    className="hide_icon d-md-none"
                    data-text={t("Translate_Email_Here")}
                  >
                    {" "}
                    <span>
                      <i className="fa fa-envelope"></i>
                    </span>
                  </div>
                  <div className="d-none d-md-block">
                    <span>
                      <i className="fa fa-envelope"></i>
                    </span>{" "}
                    {t("Translate_Email_Here")}
                  </div>
                </div>
                <div className="schedule">
                  <div
                    className="hide_icon d-md-none"
                    data-text={t("Translate_Phone_Number_Here")}
                  >
                    {" "}
                    <span>
                      <i className="fa fa-phone"></i>
                    </span>
                  </div>
                  <div className="d-none d-md-block">
                    <span>
                      <i className="fa fa-phone"></i>
                    </span>{" "}
                    {t("Translate_Phone_Number_Here")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="middle_bar">
          <div className="container">
            <div className="middle_bar_inner">
              <div className="logo">
                <a href="/" className="light_mode_logo">
                  <img src="/images/logo-5.png" alt="logo" />
                </a>
                <a href="/" className="dark_mode_logo">
                  <img src="/images/logo-5.svg" alt="logo" />
                </a>
              </div>

              <div className="header_right_part">
                <div className="mainnav">
                  <ul className="main_menu">
                    <li className="menu-item active">
                      <a href="/">{t("Translate_Home_Item")}</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="#">{t("Translate_About_Item")}</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="/about">{t("Translate_About")}</a>
                        </li>
                        <li className="menu-item">
                          <a href="/team">{t("Translate_Team")}</a>
                        </li>
                        <li className="menu-item">
                          <a href="/gallery">{t("Translate_Gallery")}</a>
                        </li>
                        <li className="menu-item">
                          <a href="/faq">{t("Translate_FAQ")}</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="/services">{t("Translate_Services")}</a>
                    </li>
                    <li className="menu-item ">
                      <a href="/project">{t("Translate_Projects")}</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="#">{t("Translate_Announcements")}</a>
                      <ul className="sub-menu">
                        <li className="menu-item">
                          <a href="/vacancy">{t("Translate_Vacancy")}</a>
                        </li>
                        <li className="menu-item">
                          <a href="/blog">{t("footer.blog")}</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a href="/contact">{t("Translate_Contact")}</a>
                    </li>
                  </ul>
                </div>

                <div className="phone">
                  <LanguageToggle />
                </div>

                <button
                  className="ma5menu__toggle position-relative d-lg-none"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasHome"
                  aria-controls="offcanvasHome"
                >
                  <i className="ion-android-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileNav />
    </>
  );
};

export default Header;
