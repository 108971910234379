import React from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation
import HTMLRenderer from "../../Components/HTMLRenderer";

const VacancyList = ({ data, t }) => { // Inject the t function
  const { id, title, description } = data;

  return (
    <div className="col-lg-12 col-md-12">
      <article className="blog_post blog_post2">
        <div className="post_content">
          <div className="post_header">
            <h3 className="post_title">
              <a href={`/vacancy-detail/${id}`}>{title}</a>
            </h3>
            <div className="apply_button">
              <a
                href={`/vacancy-detail/${id}`}
                className="button"
                style={{
                  padding: "4px",
                  borderRadius: "4px",
                  margin: 0,
                  minWidth: "120px",
                }}
              >
                {t("Apply Now")} {/* Translate the "Apply Now" text */}
              </a>
            </div>
          </div>
          <div className="post_subtitle">
            <h6 style={{ fontSize: "16px", lineHeight: "18px" }}>
              {HTMLRenderer(description)}
            </h6>
          </div>

          <div className="post_footer">
            <div className="read_more" style={{ marginLeft: "12px" }}>
              <a href={`/vacancy-detail/${id}`}>
                <span>{t("Read More")}</span> {/* Translate the "Read More" text */}
              </a>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default withTranslation()(VacancyList); // Wrap with withTranslation HOC
