import React from "react";
import CountUp from "react-countup";

const FunBox02 = ({ data }) => {
  const { img, name, description } = data;
  return (
    <div className="funbox2">
      <div className="fun_content">
      <div className="fun_img">
        <img src={img} 
        style={{ width: "60px", height: "60px" }}
         alt="icon" />
      </div>
        <h1 >{name}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default FunBox02;
