

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default to English




  const changeLanguage = (event) => {
    localStorage.setItem('selectedLanguage', event.target.value);
    i18n.changeLanguage(event.target.value);
    setSelectedLanguage(event.target.value)
  };

  return (

    <div style={{ width: 100 }}>
      <select
        style={{ height: 50, textAlign: "center" }}
        value={selectedLanguage}
        onChange={changeLanguage}

      >
        <option value="en">
          <img src="images/usa-flag.svg" alt="USA Flag" /> {t("English")}
        </option>
        <option value="am">
          <img src="images/ethiopia-flag.svg" alt="Ethiopia Flag" /> {t("Amharic")}
        </option>

      </select>
    </div>


  );
};

export default LanguageDropdown;
