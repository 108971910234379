import React from 'react';

const PaginationDiv = ({ pageCount, currentPage, onPageChange }) => {
    // Function to handle previous page click
    const handlePreviousPage = () => {
        if (currentPage > 0) {
            onPageChange(currentPage - 1);
        }
    };

    // Function to handle next page click
    const handleNextPage = () => {
        if (currentPage < pageCount - 1) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <div className="pagination-div">
            <ul className="pagination">
                <li>
                    <a href="#" onClick={handlePreviousPage}>
                        <i className="ion-chevron-left"></i>
                    </a>
                </li>
                {[...Array(pageCount)].map((_, index) => (
                    <li key={index}>
                        <a
                            className={`page-number ${currentPage === index ? 'current' : ''}`}
                            href="#"
                            onClick={() => onPageChange(index)}
                        >
                            {index + 1}
                        </a>
                    </li>
                ))}
                <li>
                    <a href="#" onClick={handleNextPage}>
                        <i className="ion-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default PaginationDiv;
