import React from "react";
import { useTranslation } from "react-i18next";
import AboutBelowContent from "../../Components/ExperienceBlocks/AboutBelowContent";

const Experience = () => {
  const { t } = useTranslation();

  const headerData = {
    img: "images/about/shadow_icon1.png",
    heading: t("about.slogan"),
    subheading: t("experience.subheading"),
  };

  const ExpericenData = [
    {
      id: "1",
      title: t("experience.trustedTitle"),
      description: t("experience.trustedDescription"),
    },
    {
      id: "2",
      title: t("experience.costEffectiveTitle"),
      description: t("experience.costEffectiveDescription"),
    },
  ];

  return (
    <div className="experience section">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="group_image_holder type_1">
              <div className="expe_box">
                <div className="expe_box_inner">
                  <h1>40+</h1>
                  {t("funfact.projectsCompleted")}
                </div>
              </div>
              <div className="image_object">
              <img src="images/slider/life-ecology-water.png" alt="" />
               
                <div className="object">
                  <img src="images/about/3.png" alt="About" />
                  <img src="images/about/3.png" alt="About" />
                  <img src="images/about/3.png" alt="About" />
                  <img src="images/about/s1.png" alt="About" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="experience_content">
              <div className="section_header">
                <div className="shadow_icon">
                  <img src={headerData.img} alt="" />
                </div>
                <h6 className="section_sub_title">{headerData.subheading}</h6>
                <h1 className="section_title">{headerData.heading}</h1>
                <p className="section_desc">{t("experience.description")}</p>
                <div className="about_below">
                  {ExpericenData.map((data) => (
                    <AboutBelowContent key={data.id} data={data} />
                  ))}
                </div>
              </div>
              <a className="button" href="/about">
                {t("experience.learnMore")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
