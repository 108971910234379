import React, { useEffect } from "react";
import BlogCards01 from "../../Components/BlogCards/BlogCards01";
import LatestNews from "../../Components/LatestNews";
import usePaginatedData from "../../hooks/usePaginatedData";

const Blog = () => {
  const {
    data: BlogDataHome01,
    currentPage,
    totalPages,
    handlePageChange,
  } = usePaginatedData("blogs/", 10);

  useEffect(() => {
    handlePageChange(currentPage);
  }, []);

  return (
    <div className="blog section">
      <div className="container">
        <div className="blog_grid">
          <div className="row">
            <LatestNews />

            {BlogDataHome01 &&
              BlogDataHome01.slice(0, 2).map((data) => (
                <BlogCards01 key={data.id} data={data} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
