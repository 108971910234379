import React from "react";

const TestimonialCard01 = ({ data }) => {
  const { name, picture, description, address, rating } = data;
  return (
    <div className="item" style={{ maxHeight: 350 }}>
      <div className="testibox1">
        <div className="testibox_inner">
          <div className="testi-content">
            <ul>
              <li className="text">Rating:</li>

              {[...Array(rating)].map((_, index) => (
                <li key={index}>
                  <i className="fa fa-star"></i>
                </li>
              ))}
              {[...Array(5 - rating)].map((_, index) => (
                <li key={index}>
                  <i className="fa fa-star-o"></i>
                </li>
              ))}
            </ul>
            <p
              style={{
                height: "120px",
                overflow: "hidden",
              }}
            >
              {description}
            </p>
          </div>
          <div className="testi-top">
            <div className="testi-img">
              <img src={picture} alt="" style={{height:60,width:50,objectFit:"fill"}} />
            </div>
            <div className="testi-info">
              <h4>{name}</h4>
              <h6>{address}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard01;
