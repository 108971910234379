import { createBrowserRouter } from 'react-router-dom';

// Home Layouts
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import Blog01 from '../Pages/Blogs/Blog01';
import ContactPage from '../Pages/ContactPage';
import FAQ from '../Pages/FAQ';
import Home01 from '../Pages/Homes/Home01';
import ProjectDetails from '../Pages/Projects/ProjectDetails';
import Projects01 from '../Pages/Projects/Projects01';
import ServiceDetails from '../Pages/Services/ServiceDetails';
import Services01 from '../Pages/Services/Services01';
import Gallery from '../Pages/Gallery'
import TeamPage from '../Pages/TeamPage';

// Home Layouts02

// Shop Layouts

// Shared Layouts
import BlogDetails from '../Pages/Blogs/BlogDetails';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Vacancy from '../Pages/Vacancy/Vacancy';
import VacancyDetail from '../Pages/Vacancy/VacancyDetail';

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/project',
                element: <Projects01/>
            },
           
            
            {
                path: '/project-details/:projectId',
                element: <ProjectDetails/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
           
            {
                path: '/service-details',
                element: <ServiceDetails/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/team',
                element: <TeamPage/>
            },
            {
                path: '/faq',
                element: <FAQ/>
            },
            {
                path: '/gallery',
                element: <Gallery/>
            },
            {
                path: '/blog',
                element: <Blog01/>
            },
            {
                path: '/vacancy',
                element: <Vacancy/>
            },
            {
                path: '/vacancy-detail/:jobId',
                element: <VacancyDetail />
            },
            {
                path: '/blog-details/:blogId',
                element: <BlogDetails/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            }
        ]
    },
    
    
])

