import React from "react";
import { useTranslation } from "react-i18next";

const TopFeatures = () => {
  const { t } = useTranslation();

  return (
    <div className="top_feature">
      <div className="container">
        {/* <div className="full_image_holder">
          <img src="images/about/about_img.png" alt="" />
        </div> */}
        <div className="content_inner">
          <h1>{t("industryRecognition")}</h1>
        </div>
        <div className="logo_image_holder">
          <img src="images/about/iso_logo.jpg" alt="" style={{width:150,height:123}} />
          <img src="images/about/badge2.png" alt="" />
          <img src="images/about/badge3.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default TopFeatures;
