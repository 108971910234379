import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../axios-config";

const ReplyForm = ({ commentId }) => {
  const { t } = useTranslation(); // Initialize the translation function

  const [formData, setFormData] = useState({
    author_name: "",
    author_email: "",
    text: "",
    comment: commentId,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { author_name, author_email, text } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a POST request to your API endpoint with the blog ID
      await instance.post(`blog/comment/${commentId}/reply/`, JSON.stringify(formData));

      // Display a success message
      setSuccessMessage(t("commentSubmittedSuccessfully"));

      // Clear the form fields
      setFormData({
        author_name: "",
        author_email: "",
        text: "",
      });
    } catch (error) {
      // Display an error message
      setErrorMessage(t("commentSubmissionError"));
    }
  };

  useEffect(() => {
    // Set a timeout to clear the success message after 5 seconds
    if (successMessage) {
      const successTimeout = setTimeout(() => {
        setSuccessMessage("");
      }, 5000);

      return () => clearTimeout(successTimeout); // Clear the timeout if the component unmounts
    }
  }, [successMessage]);

  useEffect(() => {
    // Set a timeout to clear the error message after 5 seconds
    if (errorMessage) {
      const errorTimeout = setTimeout(() => {
        setErrorMessage("");
      }, 5000);

      return () => clearTimeout(errorTimeout); // Clear the timeout if the component unmounts
    }
  }, [errorMessage]);

  return (
    <div className="makeacomment">
      {/* <h4 className="widget_title">
        {t("makeAComment")}
        <span className="title_line"></span>
      </h4> */}
      <form className="contact_form" onSubmit={handleSubmit}>
        <p className="comment-notes">{t("emailNotPublished")}</p>
        <div className="form-container">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="author_name"
                  value={author_name}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder={t("namePlaceholder")}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="email"
                  name="author_email"
                  value={author_email}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder={t("emailPlaceholder")}
                  required
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <textarea
                  name="text"
                  value={text}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder={t("commentPlaceholder")}
                  required
                ></textarea>
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <input
                  className="button"
                  type="submit"
                  value={t("reply")}
                  name="submit"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      {successMessage && (
        <div className="alert alert-success" role="alert" style={{ padding: 6, fontSize: 16 }}>
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger" role="alert" style={{ padding: 6, fontSize: 16 }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default ReplyForm;
