import React from "react";
import ServiceCard01_02 from "../../Components/ServiceCards/ServiceCard01_02";
import PaginationDiv from "../../Components/TeamElements/PaginationDiv";
import { useTranslation } from "react-i18next";
import usePaginatedData from "../../hooks/usePaginatedData";
import { useEffect } from "react";

const ServiceContainer02 = () => {
  const {
    data: GalleryData,
    currentPage,
    totalPages,
    handlePageChange,
  } = usePaginatedData("gallery/", 10);

  useEffect(() => {
    handlePageChange(currentPage);
  }, []);
  return (
    <div class="main_wrapper">
      <div class="section services">
        <div class="container">
          <div class="row">
            {GalleryData.map((data) => (
              <ServiceCard01_02 key={data.id} data={data} />
            ))}
          </div>

          {/* Pagination controls */}
          <PaginationDiv
            pageCount={totalPages} // Pass the total number of pages
            currentPage={currentPage} // Pass the current page
            onPageChange={handlePageChange} // Pass the page change handler
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceContainer02;
