import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next"; // Import withTranslation
import SocialShareButtons from "../../Components/SocialShareButtons";
import PageHeader from "../../Components/common/PageHeader";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import HTMLRenderer from "../../Components/HTMLRenderer";
import VacancyDetailSidebar from "./VacancyDetailSidebar";

const VacancyDetail = () => { // Inject the t function
  const {t}=useTranslation()
  const { jobId } = useParams();
  const {
    data: jobData,
  } = useAxiosFetch(`job_vacancies/${jobId}/`);

  const {
    title,
    location,
    description,
    requirements,
    posted_date,
    expiration_date,
    responsibilities,
    salary,
    benefits,
    how_to_apply,
  } = jobData;

  const shareUrl = window.location.href;

  return (
    <>
    <PageHeader
      activeClass01="active"
      activeClass02="d-none"
      routeName01="Anouncemect"
      heading="Job Openings"
    />
     

      <div className="main_wrapper section">
        <div className="container">
          <div className="blog_details">
            <div className="post_img"></div>
            <div className="row">
              <div className="col-lg-8 col-md-12 pe-4">
                <div className="blog_details_inner">
                  <div className="post_content">
                    <div className="fulltext">
                      <p className="highlight" style={{ fontSize: "36px", marginBottom: "50px" }}>
                        {t("Job For")} {title} {/* Translate the "Job For" text */}
                      </p>
                    </div>
                    <div className="post_header">
                      <h3
                        className="post_title"
                        style={{ fontSize: 24, textTransform: "capitalize" }}
                      >
                        {t("Job Description")} {/* Translate the "Job Description" text */}
                      </h3>
                    </div>
                    <div className="fulltext">{HTMLRenderer(description)}</div>
                  </div>
                  <div className="post_content">
                    <div className="post_header">
                      <h3
                        className="post_title"
                        style={{ fontSize: 24, textTransform: "capitalize" }}
                      >
                        {t("Requirements")} {/* Translate the "Requirements" text */}
                      </h3>
                    </div>
                    <div className="fulltext">{HTMLRenderer(requirements)}</div>
                  </div>
                  <div className="post_content">
                    <div className="post_header">
                      <h3
                        className="post_title"
                        style={{ fontSize: 24, textTransform: "capitalize" }}
                      >
                        {t("Key Responsibilities")} {/* Translate the "Key Responsibilities" text */}
                      </h3>
                    </div>
                    <div className="fulltext">
                      {HTMLRenderer(responsibilities)}
                    </div>
                  </div>
                  <div className="post_content">
                    <div className="post_header">
                      <h3
                        className="post_title"
                        style={{ fontSize: 24, textTransform: "capitalize" }}
                      >
                        {t("Benefits")} {/* Translate the "Benefits" text */}
                      </h3>
                    </div>
                    <div className="fulltext">{HTMLRenderer(benefits)}</div>
                  </div>
                  <div className="post_content">
                    <div className="post_header">
                      <h3
                        className="post_title"
                        style={{ fontSize: 24, textTransform: "capitalize" }}
                      >
                        {t("How to apply")} {/* Translate the "How to apply" text */}
                      </h3>
                    </div>
                    <div className="fulltext">{HTMLRenderer(how_to_apply)}</div>
                  </div>
                  <SocialShareButtons shareUrl={shareUrl} title={title} />
                  <div className="border_line"></div>

                  <div className="border_line"></div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <VacancyDetailSidebar
                  jobInfo={{ location, posted_date, expiration_date, salary }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VacancyDetail; // Wrap with withTranslation HOC
