import React, { useEffect } from "react";
import PageHeader from "../../Components/common/PageHeader";
import PaginationDiv from "../../Components/common/Pagination";
import usePaginatedData from "../../hooks/usePaginatedData";
import VacancyList from "./VacancyList";

const Vacancy = () => {
  const {
    data: Jobs,
    currentPage,
    totalPages,
    handlePageChange,
  } = usePaginatedData("job_vacancies/", 10);

  useEffect(() => {
    handlePageChange(currentPage);
  }, []);

  return (
    <div className="main_wrapper">
      <PageHeader
        activeClass01="active"
        activeClass02="d-none"
        routeName01="Anouncemect"
        heading="Job Openings"
      />
      <div className="blog section">
        <div className="container">
          <div className="blog_grid">
            <div className="row">
              {Jobs.map((data) => (
                <VacancyList key={data.id} data={data} />
              ))}

              <PaginationDiv
                pageCount={totalPages} // Pass the total number of pages
                currentPage={currentPage} // Pass the current page
                onPageChange={handlePageChange} // Pass the page change handler
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vacancy;
