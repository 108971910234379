import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
} from 'react-share';

const SocialShareButtons = ({ shareUrl, title }) => {
    const {t}=useTranslation()
    return (
        <div className="post_footer">
            <div className="post_share">
                <ul className="share_list">
                    <li>{t("Share")}:</li>
                    <FacebookShareButton hashtag={"#"+title} quote={title} url={"https://bigetaplc.com"}>
                        <li className="facebook">
                            <a href='#'>

                                Facebook
                            </a>
                        </li>
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl} title={title}>
                        <li className="twitter">
                            <a href='#'>

                                Twitter
                            </a>
                        </li>
                    </TwitterShareButton>
                    <LinkedinShareButton url={shareUrl} title={title}>
                        <li className="linkedin">
                            <a href='#'>


                                Linkedin
                            </a>
                        </li>
                    </LinkedinShareButton>
                </ul>
            </div>
        </div>
    );
};

export default SocialShareButtons;
