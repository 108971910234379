import React from "react";

const TeamCard = ({ data }) => {
  const { image, position, name, facebook_link, twitter_link, linkedin_link } =
    data;

  return (
    <div className="item">
      <div className="team_construction">
        <figure className="team_construction_inner">
          <a href="/team">
            <img
              src={image}
              alt={name}
              loading="lazy"
              height={350}
              width={"100%"}
              style={{ objectFit: "cover" }}
            />
          </a>
          <div className="team-box__info">
            <a href="/team" className="name h5">
              {name}
            </a>
            <p className="position">{position}</p>
          </div>
        </figure>
        <div className="team_hover_content">
          <ul className="speakers-social-lists-simple">
            <ul className="speakers-social-lists-simple">
              {facebook_link && (
                <li>
                  <a href={facebook_link} className="fa fa-facebook-square"></a>
                </li>
              )}
              {twitter_link && (
                <li>
                  <a href={twitter_link} className="fa fa-twitter"></a>
                </li>
              )}
              {linkedin_link && (
                <li>
                  <a href={linkedin_link} className="fa fa-linkedin"></a>
                </li>
              )}
            </ul>
          </ul>
          <h2 className="speaker-title-simple">
            <a href="/team">{name}</a>
          </h2>
          <p>{position}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
