import React from 'react';
import { useTranslation } from 'react-i18next';

const LatestNews = () => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div className="col-lg-4 col-md-12">
      <div className="section_header text-left">
        <h6 className="section_sub_title">{t('latestNews')}</h6>
        <h1 className="section_title">{t('ourLatestNews')}</h1>
        <p className="section_desc">{t('companyDescription')}</p>
      </div>
      <div className="read_more read_all">
        <a className="button" href="blog">{t('learnMore')}</a>
      </div>
    </div>
  );
};

export default LatestNews;
