import React from "react";
import { withTranslation } from "react-i18next"; // Import withTranslation
import getDayMonthYear from "../../utills/getDayMonthyear";

function VacancyDetailSidebar({ jobInfo, t }) { // Inject the t function
  const { location, posted_date, expiration_date, salary } = jobInfo;
  const { day, month, year } = getDayMonthYear(posted_date);
  const { day: ExpireDay, month: ExpireMonth, year: ExpireYear } = getDayMonthYear(expiration_date);

  return (
    <div className="sidebar">
      <div id="recent-posts-1" className="widget widget_recent_posts">
        <h4 className="widget_title">
          {t("Job Infos")} {/* Translate the "Job Infos" text */}
          <span className="title_line"></span>
        </h4>
        <div className="sidebar_recent_posts">
          <ul className="recent_post_list">
            <li className="recent_post_item">
              <div className="recent_post_image">
                <img
                  className="primary_img"
                  src="/images/job_info/job_posted1.png"
                  alt=""
                />
              </div>
              <div className="recent_post_content">
                <h5>
                  <a href="/#" style={{ cursor: "default" }}>{t("Date Posted")}</a> {/* Translate the "Date Posted" text */}
                </h5>
                <h6>{`${day} ${month} ${year}`}</h6>
              </div>
            </li>
            <li className="recent_post_item">
              <div className="recent_post_image">
                <img
                  className="primary_img"
                  src="/images/job_info/deadline.png"
                  alt=""
                />
              </div>
              <div className="recent_post_content">
                <h5>
                  <a href="/#" style={{ cursor: "default" }}>{t("Expiration date")}</a> {/* Translate the "Expiration date" text */}
                </h5>
                <h6>{`${ExpireDay} ${ExpireMonth} ${ExpireYear}`}</h6>
              </div>
            </li>
            <li className="recent_post_item">
              <div className="recent_post_image">
                <img
                  className="primary_img"
                  src="/images/job_info/salary1.png"
                  alt=""
                />
              </div>
              <div className="recent_post_content">
                <h5>
                  <a href="/#" style={{ cursor: "default" }}>{t("Salary")}</a> {/* Translate the "Salary" text */}
                </h5>
                <h6>{salary}</h6>
              </div>
            </li>
            <li className="recent_post_item">
              <div className="recent_post_image">
                <img
                  className="primary_img"
                  src="/images/job_info/location1.png"
                  alt=""
                />
              </div>
              <div className="recent_post_content">
                <h5>
                  <a href="/#" style={{ cursor: "default" }}>{t("Location")}</a> {/* Translate the "Location" text */}
                </h5>
                <h6>{location}</h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(VacancyDetailSidebar); // Wrap with withTranslation HOC
