import React from "react";
import { useTranslation } from "react-i18next";
import FunBox01 from "../../Components/FunFactElements/FunBox01";
import useAxiosFetch from "../../hooks/useAxiosFetch";

const FunFact01 = () => {
  const { t } = useTranslation();

  const { data: FunData, loading, error } = useAxiosFetch("funfacts/");

  if (loading) {
    return;
  }

  return (
    <div id="funfacts" className="funfacts">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="section_header">
              <h6 className="section_sub_title">{t("funfact.subTitle")}</h6>
              <h1 className="section_title">{t("funfact.title")}</h1>
            </div>
            <div className="fun_bottom">
              <div className="row">
                {FunData &&
                  FunData.map((data) => <FunBox01 key={data.id} data={data} />)}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="man_img">
              {/* You can add an image here if needed */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunFact01;
