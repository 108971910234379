import React, { useEffect } from 'react';
import PaginationDiv from '../Components/TeamElements/PaginationDiv'; // Import PaginationDiv
import TeamCard02 from '../Components/TeamElements/TeamCard02';
import PageHeader from '../Components/common/PageHeader';
import usePaginatedData from '../hooks/usePaginatedData';

const TeamPage = () => {
    const { data:teamData, currentPage, totalPages, handlePageChange } = usePaginatedData('teams/', 10);

    useEffect(() => {
      handlePageChange(currentPage);
    }, []);

    return (
        <>
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='Team' heading='Our Team' />

            <div className="main_wrapper">
                <div className="section team">
                    <div className="container">
                        <div className="row">
                            {
                                teamData.map(data => (
                                    <TeamCard02
                                        key={data.id}
                                        teamData={data}
                                    />
                                ))
                            }
                        </div>

                        {/* Pagination controls */}
                        <PaginationDiv
                            pageCount={totalPages} // Pass the total number of pages
                            currentPage={currentPage} // Pass the current page
                            onPageChange={handlePageChange} // Pass the page change handler
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeamPage;
