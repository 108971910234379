import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initReactI18next } from 'react-i18next';

// Import your language files
import en from './locales/en.json';
import am from './locales/am.json';
import i18next from 'i18next';

// Function to get the selected language from local storage
const getSelectedLanguage = () => {
  return localStorage.getItem('selectedLanguage') || 'en'; // Default to English if not found
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      am: {
        translation: am,
      },
    },
    lng: getSelectedLanguage(), // Use the selected language from local storage
    fallbackLng: 'en', // Fallback language if the selected language file is missing
    interpolation: {
      escapeValue: false, // React already escapes strings
    },
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Loader/> */}
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
