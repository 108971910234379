import Isotope from "isotope-layout";
import React, { useEffect, useState } from "react";
import ProjectCard01 from "../../Components/ProjectElements/ProjectCard01";
import { useTranslation } from "react-i18next";
import useAxiosFetch from "../../hooks/useAxiosFetch";

const ProjectHome02 = () => {
  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState("");
  const {
    data: ProjectHomeData01,
    loading,
    error,
  } = useAxiosFetch("projects/?category__name="+categoryName);
  const { data: ProjectCategory } = useAxiosFetch("projects/category/");

  const [filterKey, setFilterKey] = useState("*");

  useEffect(() => {
    Isotope.current = new Isotope(".grid", {
      itemSelector: ".element-item",
      percentPosition: true,
      // layoutMode: 'packery',
      // masonry: {
      //     columnWidth: '.grid-sizer'
      // },
      transformsEnabled: true,
      transitionDuration: "700ms",
      resize: true,
      fitWidth: true,
      // columnWidth: '.grid-sizer',
    });

    // cleanup
    return () => Isotope.current.destroy();
  });

  // handling filter key change
  useEffect(() => {
    filterKey === "*"
      ? Isotope.current.arrange({ filter: `*` })
      : Isotope.current.arrange({ filter: `.${filterKey}` });
  });

  const handleFilterKeyChange = (key) => () => {
    setFilterKey(key);

    setCategoryName("");
  };
  const handleFilterKeyChange1 = (key) =>{
    // setFilterKey(key);
    // alert(key)

    setCategoryName(key);
  };

  return (
    <div className="project_iso project_iso_bg">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section_header section_header_white text-center">
              {/* <div className="shadow_icon">
                <img src="images/shadow_icon4.png" alt="" />
              </div> */}
              <h6 className="section_sub_title">
                {t("projectHeader.subTitle")}
              </h6>
              <h1 className="section_title">{t("projectHeader.title")}</h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="button-group filters-button-group button_color">
              <button className="button" onClick={handleFilterKeyChange("*")}>
                {t("filters.all")} <sup className="filter-count"></sup>
              </button>
              {ProjectCategory &&
                ProjectCategory.map((tag, index) => (
                  <button
                    key={tag.id}
                    className="button"
                    onClick={()=>handleFilterKeyChange1(tag.name)}
                  >
                    {tag.name}
                    <sup className="filter-count"></sup>
                  </button>
                ))}

              <div className="grid grid-4 gutter-15">
                {ProjectHomeData01 &&
                  ProjectHomeData01.map((data) => (
                    <ProjectCard01 key={data.id} data={data} />
                  ))}
              </div>
            </div>
          </div>
          <div className="col-12 box_bottom">
            <p>{t("seeAllProjects.message")}</p>
            <a href="/project">{t("seeAllProjects.linkText")}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHome02;
