import React from "react";

const ServiceCard01_02 = ({ data }) => {
  const { image,title} = data;

  return (
    <div class="col-lg-3 col-md-6">
      <div class="iconbox">
        <div class="iconbox_wrapper">
          <div class="iconbox_image">
            <img src={image} alt="img" height={350} width={"100%"} style={{objectFit:"fill"}}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard01_02;
