import $ from "jquery";
import React, { useEffect } from "react";
import PageHeader from "../Components/common/PageHeader";
import Banner from "../Containers/Banner";
import { useTranslation } from "react-i18next";
import useAxiosFetch from "../hooks/useAxiosFetch";

const FAQ = () => {
  const { t } = useTranslation();
  const { data: faqData, loading, error } = useAxiosFetch("faq/");
  useEffect(() => {
    // accordion
    $(".accordion").on("click", ".accordion_tab", function () {
      $(this).next().slideDown();
      $(".accordion_info").not($(this).next()).slideUp();
    });

    $(".accordion").on("click", ".item", function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
  }, []);

  return (
    <>
      <PageHeader
        activeClass01="active"
        activeClass02="d-none"
        routeName01="FAQ"
        heading="Frequently Asked Question"
      />

      <div className="main_wrapper">
        <div className="faq">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="images-faq">
                  <img src="images/faq3.png" alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="section_header">
                  <h6 className="section_sub_title">
                    Frequently Asked Question
                  </h6>
                  <h1 className="section_title">
                    Do You Want to Know Anything From Us?
                  </h1>
                </div>
                <div className="accordion">
                  {faqData.map((faq, index) => (
                    <div
                      className={`item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <div className="accordion_tab">
                        <h2 className="accordion_title">{faq.question}</h2>
                        <span className="accordion_tab_icon">
                          <i className="open_icon ion-ios-arrow-down"></i>
                          <i className="close_icon ion-ios-arrow-up"></i>
                        </span>
                      </div>
                      <div className="accordion_info">{faq.answer}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </>
  );
};

export default FAQ;
