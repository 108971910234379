import React from "react";
import { useTranslation } from "react-i18next";
import SubscriptionForm from "./SubscriptionForm";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer_above">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="footer_widget footer_widget_padding">
                <h4 className="widget_title">{t("footer.aboutBigeta")}</h4>
                <p>{t("footer.aboutBigetaDesc")}</p>
                <div className="subscribe">
                  <SubscriptionForm />
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer_widget footer_links">
                <h4 className="widget_title">{t("footer.usefulLinks")}</h4>
                <div className="footer_nav">
                  <ul className="footer_menu">
                    <li className="menu-item">
                      <a href="/about">{t("footer.aboutUs")}</a>
                    </li>
                    <li className="menu-item">
                      <a href="/services">{t("footer.services")}</a>
                    </li>
                    <li className="menu-item">
                      <a href="/project">{t("footer.projects")}</a>
                    </li>
                    <li className="menu-item">
                      <a href="/vacancy">{t("footer.vacancies")}</a>
                    </li>
                    <li className="menu-item">
                      <a href="/gallery">{t("Translate_Gallery")}</a>
                    </li>
                    <li className="menu-item">
                      <a href="/contact">{t("footer.contactUs")}</a>
                    </li>
                    <li className="menu-item">
                      <a href="/blog">{t("footer.blog")}</a>
                    </li>
                    <li className="menu-item">
                      <a href="/faq">{t("footer.faq")}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer_widget">
                <h4 className="widget_title">{t("footer.address")}</h4>
                <ul>
                  <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span>{t("footer.addressLine")}</span>
                  </li>
                  <li>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span>{t("footer.emailAddress")}</span>
                  </li>
                  <li>
                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                    <span>{t("footer.openingHours")}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="footer_bottom_inner">
            <div className="logo">
              <a href="/">
                <img src="/images/logo-5.svg" alt="Logo" />
              </a>
            </div>
            <div className="copyright">
              <p>
                &copy; {new Date().getFullYear()}{" "}
                <a href="https://toptech.et" target="_blank" rel="noreferrer">
                  Toptech IT Solutions PLC.
                </a>{" "}
                {t("footer.allRightsReserved")}
              </p>
            </div>
            <div className="footer_nav_bottom">
              {/* <ul>
                <li>
                  <a href="#">{t('footer.termsConditions')}</a>
                </li>
                <li>
                  <a href="#">{t('footer.privacyPolicy')}</a>
                </li>
              </ul> */}
            </div>
            <div className="totop">
              <a href="#">
                <i className="ion-ios-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
