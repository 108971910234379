import { useState, useEffect } from 'react';
import instance from '../axios-config'; // Import your axios instance

const usePaginatedData = (endpoint, itemsPerPage = 10) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchDataForPage = (page) => {
      const url = endpoint.includes('?') ? '&' : '?'; // Corrected concatenation

      instance.get(`${endpoint}${url}page=${page + 1}`)
        .then(response => {
          const apiData = response.data.results;
          const apiTotalPages = Math.ceil((response.data.count) / itemsPerPage);

          setData(apiData);
          setTotalPages(apiTotalPages);
        })
        .catch(error => {
          console.error(`Error fetching data from ${endpoint}:`, error);
        });
    };

    fetchDataForPage(currentPage);
  }, [currentPage, endpoint, itemsPerPage]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return { data, currentPage, totalPages, handlePageChange };
};

export default usePaginatedData;
