import React from "react";
import Partners01 from "../../Components/Partners/Partners01";
import Banner from "../../Containers/Banner";
import Blog from "../../Containers/Blogs/Blog";
import Experience from "../../Containers/Experiences/Experience";
import FunFact01 from "../../Containers/Funfacts/FunFact01";
import ProjectHome01 from "../../Containers/ProjectContainers/ProjectHome01";
import ServiceHome01 from "../../Containers/ServiceContainers/ServiceHome01";
import Testimonial01 from "../../Containers/Testimonials/Testimonial01";
import Testimonial02 from "../../Containers/Testimonials/Testimonial02";
import Slider from "../../Containers/ThemeSliders/Slider";
import { useTranslation } from "react-i18next";
import ProjectHome02 from "../../Containers/ProjectContainers/ProjectHome02";
import Partners02 from "../../Components/Partners/Partners02";

const Home01 = () => {
  const { t } = useTranslation();
  return (
    <div className="main_wrapper">
      <Slider />
      {/* <ServiceHome01 /> */}
      <Experience />
      <FunFact01 />

      <ProjectHome02 />

      <Banner
        title={t("bannerTitle")}
        heading={t("bannerHeading")}
        phones={{
          phone1: "+251 116 479 504",
          phone2: "+251 116 479 506",
        }}
        bannerType="banner type_3"
      />

      <Testimonial01 pb="pd_btom_80" />

      <Blog />
      <Partners01 />
      {/* <Partners02 pt='pd_tp_110' /> */}
    </div>
  );
};

export default Home01;
