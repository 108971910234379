// axios-config.js
import axios from 'axios';

// Your API key
const apiKey = 'jRGep5e7MfKMEq0j0Wp3';

const instance = axios.create({
  baseURL: 'https://backend.bigetaplc.com/api/', // Replace with your API base URL
  
// baseURL: 'http://localhost:8000/api/', // Replace with your API base URL
  // timeout: 10000, // Request timeout in milliseconds
  headers: {
    'Content-Type': 'application/json',
    'X-API-Key': apiKey, 

  },
});

export default instance;
