import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios-config";

function SubscriptionForm() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const clearMessages = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    clearMessages();

    const emailRegex = /^[A-Za-z0-9+_.-]+@(.+)$/;
    if (!emailRegex.test(email)) {
      setErrorMessage(t('footer.invalidEmail'));
      setTimeout(clearMessages, 5000); // Hide error message after 5 seconds
      return;
    }

    axiosInstance
      .post("subscribe/", { email })
      .then((response) => {
        if (response.status === 201) {
          setSuccessMessage(t('footer.subscriptionSuccess'));
          setEmail("");
          setTimeout(clearMessages, 5000); // Hide success message after 5 seconds
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400 && error.response.data.detail === "You are already subscribed with this email address.") {
          setErrorMessage(t('footer.alreadySubscribed'));
        } else {
          setErrorMessage(t('footer.subscriptionError'));
        }
        setTimeout(clearMessages, 5000); // Hide error message after 5 seconds
      });
  };

  return (
    <form className="form-inline" onSubmit={handleSubmit}>
      <div className="input-group mb-2 mr-sm-2">
        <input
          type="email"
          className="form-control"
          placeholder={t('footer.emailPlaceholder')}
          value={email}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit" className="btn">
        {t('footer.subscribe')}
      </button>

      {successMessage && (
        <div className="alert alert-success" role="alert" style={{ padding: 6, fontSize: 16 }}>
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger" role="alert" style={{ padding: 6, fontSize: 16 }}>
          {errorMessage}
        </div>
      )}
    </form>
  );
}

export default SubscriptionForm;
