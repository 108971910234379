import React, { useEffect, useTransition } from "react";
import { Controller, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import TeamCard from "../Components/TeamElements/TeamCard";
import usePaginatedData from "../hooks/usePaginatedData";
import { useTranslation } from "react-i18next";

const Team = (props) => {
  const { t } = useTranslation();
  const {
    data: TeamData,
    currentPage,
    totalPages,
    handlePageChange,
  } = usePaginatedData("teams/", 10);

  useEffect(() => {
    handlePageChange(currentPage);
  }, []);
  return (
    <div className={`team ${props.pb}`}>
      <div className="container">
        <div className="section_header text-center">
          <div className="shadow_icon">
            <img src="images/shadow_icon5.png" alt="" />
          </div>
          <h6 className="section_sub_title">{t("team_sub_title")}</h6>
          <h1 className="section_title">{t("team_title")}</h1>
          <p className="section_desc">{t("team_desc")}</p>
        </div>
        <div className="row">
          <div className="col">
            <Swiper
              className="owl_team owl-carousel owl-theme"
              modules={[Pagination, Navigation, Controller]}
              // slidesPerView={3}
              breakpoints={{
                640: {
                  width: 576,
                  slidesPerView: 1,
                },
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
              }}
              spaceBetween={50}
              loop={true}
              // centeredSlides={true}
              controller={{ inverse: true }}
              // pagination={{
              //     el: '.swiper-pagination',
              //     clickable: true,
              // }}
              navigation={{
                prevEl: ".owl-prev .disabled",
                nextEl: ".owl-next",
              }}
            >
              {TeamData.map((data) => (
                <SwiperSlide>
                  <TeamCard key={data.id} data={data} />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <!-- Add Buttons --> */}
            {/* <div className="swiper-navigation">
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                        </div> */}

            {/* <!-- Add Buttons --> */}
            {/* <div className="owl-nav">
                            <button type="button" role="presentation" className="owl-prev disabled"><i className="fa fa-long-arrow-left"></i></button>
                            <button type="button" role="presentation" className="owl-next"><i className="fa fa-long-arrow-right"></i></button>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
