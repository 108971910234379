import React from "react";
import { useTranslation } from "react-i18next";
import ServiceCard02_01 from "../../Components/ServiceCards/ServiceCard02_01";

const ServiceContainer01 = () => {
  const { t } = useTranslation();

  const servicesData = [
    {
      img: "images/services/s9.png",
      img02: "/images/services/Service_Hover_images/water.jpg",
      title: t("services.0.title"),
      description: t("services.0.description"),
    },

    {
      img: "images/services/s12.png",
      img02: "/images/services/Service_Hover_images/swerage.jpg",
      title: t("services.1.title"),
      description: t("services.1.description"),
    },
    {
      img: "images/services/s5.png",
      img02: "/images/services/Service_Hover_images/building.jpg",
      title: t("services.2.title"),
      description: t("services.2.description"),
    },
    {
      img: "images/services/s1.png",
      img02: "/images/services/Service_Hover_images/road.jpg",
      title: t("services.3.title"),
      description: t("services.3.description"),
    },
    {
      img: "images/services/s7.png",
 
      img02: "images/reservior.jpg",

      title: t("services.4.title"),
      description: t("services.4.description"),
    },
    {
      img: "images/services/s4.png",
      img02: "/images/services/Service_Hover_images/rental.jpg",
      title: t("services.5.title"),
      description: t("services.5.description"),
    },
    
  ];

  return (
    <div className="main_wrapper">
      <div className="section service services_inner_page">
        <div className="container">
          <div className="row">
            {servicesData.map((data, index) => (
              <ServiceCard02_01 key={index} data={data} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContainer01;
