import React, { useLayoutEffect, useRef, useState } from "react";
import LazyLoad from 'react-lazyload';


const ProjectCard01 = ({ data }) => {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const monitorWidth = windowSize.current[0];

    // console.log(monitorWidth);


    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    },[]);

    let elHeight = Number(dimensions.height);
    // console.log(typeof elHeight);

    const {id:projectId,
      project_name,
      description,
      address,
      client,
      date_of_work,
      image,
      execution_amount,
      category: { id, name },
    } = data;

    return (

        <div ref={targetRef} className={`element-item ${name}`} >
            <div className="teambox">
                <LazyLoad height={monitorWidth >= 1536 ? 300 : 400} style={{marginVertical:5}} once>
                    <img src={image} alt="" style={{height:monitorWidth >= 1536 ? 300 : 400}} />
                </LazyLoad>

                <div className="teambox_inner">
                   
                    <div className="teambox_intro">
                        <div className="team_flex team_size">
                            <h5><a href={`/project-details/${projectId}`}>{project_name}</a></h5>
                            <h6>{address}</h6>
                            {/* <br />
                            <p>width: {dimensions.width}</p>
                            <p>height: {dimensions.height}</p> */}
                            {/* <h2>Width: {windowSize.current[0]}</h2>
                            <h2>Height: {windowSize.current[1]}</h2> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default ProjectCard01;