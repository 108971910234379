
import { useTranslation } from 'react-i18next';
import React from 'react';
const BannerText = (props) => {
    const {t}=useTranslation()
    return (
        <div className="banner_text">
            <img src="images/phone3.png" alt="" />
            <h1>{t(props.title)}</h1>
        </div>
    );
};

export default BannerText;