import React from 'react';

function Map() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.27910631105632!2d38.83643776805343!3d9.021211500000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b9b965b717841%3A0x8f69801103fc3961!2zR29sZ290aGEgVG93ZXIgfCBDTUMgfCDhjI7hiI3hjI7hibMg4Ymz4YuI4YitIHwg4Yiy4Yqk4Yid4Yiy!5e0!3m2!1sen!2sbe!4v1694952831668!5m2!1sen!2sbe"
      width="100%"
      height="400"
      frameBorder="0"
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Map"
    ></iframe>
  );
}

export default Map;
