import React from "react";
import Video from "../../Components/BannerElements/Video";
import PageHeader from "../../Components/common/PageHeader";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import { useParams } from "react-router-dom";
import getDayMonthYear from "../../utills/getDayMonthyear";
import SocialShareButtons from "../../Components/SocialShareButtons";
import CommentList from "./CommentList";
import CommentForm from "./CommentForm";

const BlogDetails = () => {
  const { blogId } = useParams();
  const { data: blogData, loading, error } = useAxiosFetch(`blogs/${blogId}/`);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const {
    blogClass,
    id,
    title,
    content,
    author,
    created_at,
    updated_at,
    is_published,
    tags,
    image,
  } = blogData;
  const { day, month, year } = getDayMonthYear(updated_at);
  const shareUrl = window.location.href;

  return (
    <>
      <PageHeader
        activeClass02="active"
        routeName02="Blog Details"
        routeName01="Blog"
        heading={title} // Use the blog title as the heading
      />

      <div className="main_wrapper section">
        <div className="container">
          <div className="blog_details">
            <div className="post_img">
              <img src={image} alt="blog" />
              <div className="calendar">
                <a href="#">
                  <span className="date">{day}</span>
                  <br />
                  {month}
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12 pe-4">
                <div className="blog_details_inner">
                  <div className="post_content">
                    <div className="post_header">
                      <div className="categ">
                        {tags.map((tag, index) => (
                          <a href="/about">{tag}</a>
                        ))}
                      </div>
                      <h3 className="post_title">{title}</h3>
                    </div>
                    <div className="fulltext">{content}</div>
                  </div>
                  <SocialShareButtons shareUrl={shareUrl} title={title} />
                  <div className="border_line"></div>

                  <div className="author_div">
                    <div className="author">
                      <img
                        alt="Author's Avatar"
                        src={author.image ?? "/images/author3.png"}
                        className="avatar"
                      />
                    </div>
                    <div className="author-block">
                      <h5 className="author_name">{author.name}</h5>
                      <p className="author_intro">{author.position}</p>
                      <div className="social_media">
                        <ul className="social_list">
                          {author.facebook_link && (
                            <li>
                              <a
                                href={author.facebook_link}
                                className="fa fa-facebook-square"
                              ></a>
                            </li>
                          )}
                          {author.twitter_link && (
                            <li>
                              <a
                                href={author.twitter_link}
                                className="fa fa-twitter"
                              ></a>
                            </li>
                          )}
                          {author.linkedin_link && (
                            <li>
                              <a
                                href={author.linkedin_link}
                                className="fa fa-linkedin"
                              ></a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="border_line"></div>

                  <CommentList blogId={id} />

                  <CommentForm blogId={id} />
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="sidebar">
                  {/* ... Other sidebar widgets ... */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
