import React from "react";
import { useTranslation } from "react-i18next";
import "./CompanyInfo.css";

const CompanyInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="container company-info">
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-4">{t("company.strategicPlan")}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h4 style={{ color: "black", marginTop: "20px" }}>
            {t("company.vision")}
          </h4>
          <ul>
            {t("company.visionList", { returnObjects: true }).map(
              (item, index) => (
                <li key={index}>
                  <i className="fa fa-eye"></i> {item}
                </li>
              )
            )}
          </ul>
        </div>
        <div className="col-md-12">
          <h4 style={{ color: "black", marginTop: "20px" }}>
            {t("company.mission")}
          </h4>
          <ul>
            {t("company.missionList", { returnObjects: true }).map(
              (item, index) => (
                <li key={index}>
                  <i className="fa fa-bullseye"></i> {item}
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h4 style={{ color: "black", marginTop: "20px" }}>
            {t("company.values")}
          </h4>
          <ul>
            {t("company.valuesList", { returnObjects: true }).map(
              (item, index) => (
                <li key={index}>
                  <i className="fa fa-heart"></i> {item}
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h4
            style={{ color: "black", marginTop: "20px", marginBottom: "20px" }}
          >
            {t("company.qualityPolicy")}
          </h4>
          <ul>
            {t("company.qualityPolicyList", { returnObjects: true }).map(
              (item, index) => (
                <li key={index}>
                  <i className="fa fa-check-circle"></i> {item}
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
