import React from "react";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Gallery = (props) => {
    const { t } = useTranslation();
    const { data: partnerData, loading, error } = useAxiosFetch("gallery/");
  
    // Slick settings
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        lazyLoad: true,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    return (
      <div className={`patner_2 ${props.pt} pd_btom_110`}>
        <div className="container">
          <div className="section_header text-center">
            <h1 className="section_title">{t("Translate_Gallery")}</h1>
            <p className="section_desc">{t('exploreOurGallery')}</p>
          </div>
          <div className="row">
            <div className="col">
              <Slider {...settings}>
                {partnerData&&partnerData.results.map((partner, index) => (
                  <div className="patner_2" key={index}>
                    
                      <img src={partner.image} alt={partner.title} style={{height:300}} />
                    
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="col-12 box_bottom">
            <p style={{color:"inherit"}}>{t("seeMore")}</p>
            <a href="/gallery">{t("seeAllProjects.linkText")}</a>
          </div>
        </div>
      </div>
    );
  };
  
  export default Gallery;
  

