import React, { useEffect } from 'react';
import BlogCards01 from '../../Components/BlogCards/BlogCards01';
import PageHeader from '../../Components/common/PageHeader';
import Pagination from '../../Components/common/Pagination';
import usePaginatedData from '../../hooks/usePaginatedData';

const Blog01 = () => {
    const { data:BlogData01, currentPage, totalPages, handlePageChange } = usePaginatedData('blogs/', 10);

    useEffect(() => {
      handlePageChange(currentPage);
    }, []);
    return (
        <div className="main_wrapper">
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='Blogs' heading='Blogs' />
            <div className="blog section">
                <div className="container">
                    <div className="blog_grid">
                        <div className="row">
                            {
                                BlogData01.map(data => <BlogCards01
                                    key={data.id}
                                    data={data}
                                />)
                            }
                           <Pagination
                            pageCount={totalPages} // Pass the total number of pages
                            currentPage={currentPage} // Pass the current page
                            onPageChange={handlePageChange} // Pass the page change handler
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog01;