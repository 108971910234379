function getDayMonthYear(dateString) {
    const dateObj = new Date(dateString);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];
    if (isNaN(dateObj.getTime())) {
        // Invalid date string
        return { day: null, month: null, year: null };
    }

    const day = dateObj.getDate();
    const month = months[dateObj.getMonth() + 1]; // Months are 0-indexed, so add 1
    const year = dateObj.getFullYear();

    return { day, month, year };
}
export default getDayMonthYear