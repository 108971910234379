import React, { useState } from "react";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Set the root element to manage accessibility

const modalStyles = {
  overlay: {
   
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    marginLeft:"auto"
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "38%", // Maximum width not exceeding 50%
    maxHeight: "420px", // Maximum height of 300px
    background: "white",
    padding: "20px",
    borderRadius: "4px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  imageContainer: {
    flex: 1, // Occupy half of the content
    padding: "0 20px",
    width:"25%"
  },
  infoContainer: {
    flex: 1, // Occupy half of the content
    padding: "0 20px",
    color: "black", // Set text color to black
  },
  closeButton: {
    alignSelf: "flex-end", // Align to the right
    marginTop: "auto", // Push the button to the bottom
    padding: 5,
    minWidth: "60px",
  },
};

const Partners02 = (props) => {
  const { t } = useTranslation();
  const { data: partnerData, loading, error } = useAxiosFetch("partners/");
  const [selectedPartner, setSelectedPartner] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: calculateSlidesToShow(),
    lazyLoad: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  function calculateSlidesToShow() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return partnerData?.length > 4 ? 5 : partnerData.length;
    } else if (screenWidth >= 768) {
      return 3; // Adjust the number of slides for tablets
    } else {
      return 1; // Adjust the number of slides for mobile
    }
  }

  const openPreview = (partner) => {
    setSelectedPartner(partner);
    document.body.style.overflow = "hidden"; // Prevent body scrolling
  };

  const closePreview = () => {
    setSelectedPartner(null);
    document.body.style.overflow = "auto"; // Enable body scrolling
  };

  return (
    <div className={`patner_2 ${props.pt}`}>
      <div className="container">
        <div className="section_header text-center">
          <h1 className="section_title">{t("ourPartner")}</h1>
        </div>
        <div className="row">
          <div className="col">
            <Slider {...settings}>
              {partnerData.map((partner, index) => (
                <div
                  className="patner_2"
                  key={index}
                  onClick={() => openPreview(partner)}
                >
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    style={{
                      maxHeight: 100,
                      marginTop: "auto",
                      marginBottom: "auto",
                      objectFit:"contain"
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Modal
        isOpen={selectedPartner !== null}
        onRequestClose={closePreview}
        contentLabel="Partner Image Preview"
       style={modalStyles}
      >
        <div style={modalStyles.imageContainer}>
          <img
            src={selectedPartner?.logo}
            alt={selectedPartner?.name}
            style={{ maxWidth: "100%", maxHeight: 150 }}
          />
        </div>
        <div style={modalStyles.infoContainer} class="footer">
          <div className="footer_widget">
          <h3 className="section_title">{selectedPartner?.name}</h3>
            <ul>
              {selectedPartner?.address && (
                <li>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  <span style={{ color: "black" }}>
                    {selectedPartner.address}
                  </span>
                </li>
              )}
              {selectedPartner?.email && (
                <li>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span style={{ color: "black" }}>
                    {selectedPartner.email}
                  </span>
                </li>
              )}
              {selectedPartner?.phone && (
                <li>
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <span style={{ color: "black" }}>
                    {selectedPartner.phone}
                  </span>
                </li>
              )}
              {selectedPartner?.fax && (
                <li>
                  <i className="fa fa-fax" aria-hidden="true"></i>
                  <span style={{ color: "black" }}>{selectedPartner.fax}</span>
                </li>
              )}
              {selectedPartner?.description && (
                <li>
                  <i className="fa fa-info" aria-hidden="true"></i>
                  <span style={{ color: "black" }}>
                    {selectedPartner.description}
                  </span>
                </li>
              )}
             
              {selectedPartner?.website && (
                <li>
                  <i className="fa fa-globe" aria-hidden="true"></i>
                  <span style={{ color: "black" }}>
                  
                      {selectedPartner.website}
                    
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
        <button
          onClick={closePreview}
          style={modalStyles.closeButton}
          className="button"
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default Partners02;
